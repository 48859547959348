import styled from 'styled-components';
import { color_dict } from '../utils/ColorDict';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: ${color_dict.backgroundColor};
`;

export const FeedContainer = styled.div`
    ${'' /* background-color: ${color_dict.darkerBackgroundCOlor}; */}
    flex: 1;
    overflow-y: scroll;
    padding-bottom: 60px;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 0;
`;

export const TopBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #252525;
    padding-right: 16px;
    padding-left: 16px;
    height: 60px;
    z-index: 1;
`;

export const TitleTypography = styled.h3`
    margin-top: 16px;
    margin-bottom: 0;
    color: white;
    text-align: center;
    font-weight: 600;
`;

export const LogoContainer = styled.div`
    height: 60%;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

export const IconButton = styled.button`
    background: none;
    border: none;
    color: white;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s ease;

    &:hover {
        opacity: 0.8;
    }
`;

export const BottomBar = styled.div`
    height: 60px;
    background-color: #252525;
    position: relative;
    border-top: 2px solid transparent;
    background-image: linear-gradient(#252525, #252525), 
        linear-gradient(135deg, #FF6B6B, #FFD93D, #6BCB77, #4D96FF);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    animation: gradient-animation 5s ease infinite;
`;

export const FloatingActionButton = styled.button`
    position: relative;
    align-self: center;

    margin-top: 20px;
    margin-bottom: 35px;

    width: 80%;
    max-width: 400px;

    padding: 15px;
    box-sizing: border-box;

    font-size: 20px;
    font-weight: 200;

    border-radius: 25px;
    border: none;

    background: linear-gradient(
        135deg,
        ${color_dict.logo_pink},
        ${color_dict.logo_pink_exciting},
        ${color_dict.logo_purple},
        ${color_dict.logo_purple_highlight}
    );
    background-size: 300% 300%;
    animation: gradient-animation 5s ease infinite;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0 4px 15px rgba(206, 32, 101, 0.3);
    transition: all 0.2s ease;

    @keyframes gradient-animation {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        background: linear-gradient(
            135deg,
            ${color_dict.logo_pink},
            ${color_dict.logo_pink_exciting},
            ${color_dict.logo_purple},
            ${color_dict.logo_purple_highlight}
        );
        background-size: 300% 300%;
        animation: gradient-animation 5s ease infinite;
        border-radius: 50%;
        z-index: -1;
        opacity: 0.7;
        filter: blur(10px);
    }

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 6px 20px rgba(206, 32, 101, 0.4);
    }

    &:active {
        transform: scale(0.95);
    }
`; 