import React, { useEffect, useState, useMemo } from 'react';
import {
    Container,
    TopBar,
    TitleTypography,
    LogoContainer,
    IconButton,
    BottomBar,
    FloatingActionButton,
    FeedContainer,
} from './DashboardScreen.styles';
import Feed from '../components/Feed/Feed';
import { useNavigate } from 'react-router-dom';
import { getUserFeed, getMediaUrl, getCreditHistory } from '../services/api';
import { transformBundle } from '../utils/bundleTransforms';
import MorphResult from '../components/Feed/MorphResult';
import { selectCurrentUser, updateUser } from '../features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '../services/api';


const DashboardChoice = ({ chosenDashboard, setChosenDashboard, setReloadBundles }) => {
    const dashboardOptions = ["Morphs", "Videos"]

    return (
        <div style={{
            // backgroundColor:'red',

            padding: '15px 5px',
            boxSizing: 'border-box',

            zIndex: 1,

            fontSize: '25px',
            fontWeight: 'bold',

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
        }}>
            {dashboardOptions.map(option => (
                <div 
                key={option} 
                style={{
                    // flex:'1 0 0%',

                    color: option === chosenDashboard ? 'white' : 'rgb(170,170,170)',
                    textAlign: 'center',

                    cursor: 'pointer',

                    overflow: 'hidden',
                }}
                    onClick={() => {
                        setChosenDashboard(option);
                        setReloadBundles(true);
                    }}
                >
                    {option}
                </div>
            ))}
        </div>
    )
}

const DashboardScreen = ({ isSubscribed, setReloadUser, style, userID }) => {
    const navigate = useNavigate();
    const [bundles, setBundles] = useState([]);
    const [reloadBundles, setReloadBundles] = useState(true);
    const [chosenDashboard, setChosenDashboard] = useState("Morphs");
    const [showingResult, setShowingResult] = useState(false);
    const [chosenBundleData, setChosenBundleData] = useState(null);
    const [hasBoughtBefore, setHasBoughtBefore] = useState(false);
    const [group, setGroup] = useState('A');

    useEffect(() => {
        console.log('userID: ', userID);
        // Set a random group (A or B) based on userID as seed
        const seed = userID ? parseInt(userID.replace(/\D/g, '') || '0', 10) : 0;
        
        // Simple hash function to get a number from the seed
        const hash = seed % 2;

        // Assign group based on hash value
        const assignedGroup = hash === 0 ? 'A' : 'B';
        setGroup(assignedGroup);
        
        console.log(`User assigned to group: ${assignedGroup}`);
    }, [userID]);

    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);
    // useEffect(() => {
    //     const pollInterval = setInterval(() => {
    //         getCurrentUser().then(response => {
    //             if (response.success) {
    //                 dispatch(updateUser(response.user));
    //             }
    //             else {
    //                 console.log('error: ', response)
    //             }
    //         }).catch(error => {
    //             // Silently handle any errors from getCurrentUser
    //             console.log('Error polling user:', error);
    //         });
    //     }, 10000);
    // }, []);

    const dashboardOptionToType = {
        "Morphs": 1,
        "Videos": 3,
    }

    const relevantDashboardBundles = useMemo(() => {
        return bundles.filter(bundle => bundle.type === dashboardOptionToType[chosenDashboard]);
    }, [bundles, chosenDashboard]);


    useEffect(() => {
        setReloadUser(true);
        getCreditHistory().then(history => {
            console.log('history: ', history)
            const onlyPositiveTransactions = history.transactions.filter(transaction => transaction.amount > 0);
            setHasBoughtBefore(onlyPositiveTransactions.length > 0 || user.credits > 0);
        });
    }, [user.credits]);

    useEffect(() => {
        const fetchBundles = async () => {

            try {
                const response = await getUserFeed();
                if (response.success) {
                    // Transform the API response using the utility function
                    const transformedBundles = response.bundles.map(transformBundle);

                    console.log('bundles: ', response.bundles)
                    console.log('transformedBundles: ', transformedBundles)

                    setBundles(transformedBundles);
                }
            } catch (error) {
                console.error('Error fetching bundles:', error);
                // You might want to show an error message to the user here
            }
        };

        if (reloadBundles) {
            setReloadBundles(false);
            fetchBundles();
        }
    }, [reloadBundles]);

    const handleCameraClick = () => {
        navigate('/upload');
    };

    const handleUserClick = () => {
        navigate('/user-profile');
    };

    return (
        <div style={{
            width: '100%',
            height: '100%',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'stretch',

            overflowY: 'hidden',

            position: 'relative',
            minHeight: 1,
        }}>

            {
                (!showingResult) ? (
                    <>
                        <DashboardChoice
                            chosenDashboard={chosenDashboard}
                            setChosenDashboard={setChosenDashboard}
                            setReloadBundles={setReloadBundles}
                        />
                        <FeedContainer>
                            {/* filter only the bundles that are of the chosen type */}
                            <Feed
                                bundles={
                                    relevantDashboardBundles
                                }
                                setChosenBundleData={setChosenBundleData}
                                setShowingResult={setShowingResult}
                                hasBoughtBefore={hasBoughtBefore}
                            />

                            {
                                (chosenDashboard === "Videos") && (relevantDashboardBundles.length === 0) && (
                                    <div style={{
                                        textAlign: 'center',
                                        color: 'rgb(170,170,170)',
                                        fontSize: '20px',
                                        marginTop: '20px',

                                        maxWidth: '80%',
                                    }}>
                                        To create a video you first need to create a morph
                                    </div>
                                )
                            }
                        </FeedContainer>

                        {
                            (chosenDashboard === "Morphs") && (
                                <FloatingActionButton onClick={() => { navigate('/upload'); }}>
                                    Create New {chosenDashboard === "Morphs" ? "Morph" : "Video"}
                                </FloatingActionButton>
                            )
                        }
                        
                    </>
                ) : (
                    <MorphResult
                        chosenBundleData={chosenBundleData}
                        onClose={() => {
                            setShowingResult(false);
                            setChosenBundleData(null);
                        }}
                        onCreateVideo={() => {
                            setShowingResult(false);
                            setChosenBundleData(null);
                            setChosenDashboard("Videos");
                            setReloadBundles(true);
                        }}
                        setReloadBundles={setReloadBundles}
                        hasBoughtBefore={hasBoughtBefore}
                        group={group}
                        userID={userID}
                    />
                )
            }


        </div>
    );
};

export default DashboardScreen; 