import React from 'react';
import LoadingThingie from '../common/LoadingThingie';
import { RxCross1 } from 'react-icons/rx';

const FeedCard = ({ 
    image_url,
    isProcessing,
    processingPercentage,
    errorProcessing,
    date,
    onClick 
}) => {
    return (
        <div
            style={{
                width: '160px',
                height: '200px',
                borderRadius: '20px',
                position: 'relative',
                overflow: 'hidden',
                boxShadow: 'rgb(0, 0, 0) 0px 20px 30px -10px',
            }}
            onClick={onClick}
        >
            <div style={{
                background: `linear-gradient(to bottom, rgb(0, 0, 0, 0) 0%,
rgb(0, 0, 0, 0) 70%,
rgb(0, 0, 0, 1) 95%,
rgb(0, 0, 0, 1) 100%
)`,
                position: 'absolute',
                left: 0,
                top: 0,
                width: '110%',
                height: '100%',
                zIndex: 1,
            }} />

            <div style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                left: 0,
                top: 0,
                zIndex: 0,
            }}>
                <img src={image_url} alt="bundle" style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }} />
            </div>

            <div style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                backdropFilter: isProcessing ? 'blur(8px)' : '',
                WebkitBackdropFilter: isProcessing ? 'blur(8px)' : '',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {errorProcessing && (
                    <div style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.8)',
                        padding: '20px',
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <RxCross1 size="30px" color="red" style={{ strokeWidth: '2px' }} />
                        <div style={{ textAlign: 'center', fontSize: '15px' }}>Processing Error</div>
                        <div style={{
                            marginTop: '5px',
                            textAlign: 'center',
                            fontSize: '12px',
                            color: 'rgb(170, 170, 170)',
                        }}>We've refunded your credits</div>
                    </div>
                )}

                {!errorProcessing && isProcessing && (
                    <>
                        <LoadingThingie />
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translateX(-50%) translateY(-50%)'
                        }}>
                            {processingPercentage}%
                        </div>
                    </>
                )}
            </div>

            <div style={{
                width: '100%',
                position: 'absolute',
                bottom: 10,
                textAlign: 'center',
                fontWeight: '600',
                zIndex: 2,
            }}>
                {date}
            </div>
        </div>
    );
};

export default FeedCard;
