import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import FeedCard from '../components/Feed/FeedCard';
import {
    Container,
    TopBar,
    TitleTypography,
    LogoContainer,
    IconButton,
    BottomBar,
    FloatingActionButton,
    FeedContainer,
} from './DashboardScreen.styles';
import { formatDateString } from '../utils/UtilFuncs';
import AnalysisResult from '../components/Measure/AnalysisResult';
import { getUserAnalyses, getAnalysisById, getCreditHistory } from '../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { setApiError } from '../features/error/errorSlice';
import { selectCurrentUser } from '../features/auth/authSlice';

// Enhanced mock data for facial analysis results
const dummyAnalysis = [
    { 
        id: 1, 
        images: [{ url: 'https://picsum.photos/200/300' }], 
        is_processing: false, 
        error_processing: false, 
        createdAt: '2024-03-15',
        // These additional fields would actually come from the backend in a real implementation
        // For demo purposes we just include them here
        analysisDetails: {
            canthalTilt: {
                leftEyeAngle: 5.2,
                rightEyeAngle: 4.8,
                averageAngle: 5.0,
                symmetry: 92,
                classification: 'Hunter',
                score: 85
            },
            fwhr: {
                facialWidth: 142,
                facialHeight: 190,
                ratio: 0.747,
                comparedToAverage: '+5%',
                score: 78
            },
            philtumChinRatio: {
                philtumLength: 15,
                chinLength: 45,
                ratio: 0.33,
                comparedToAverage: '-2%',
                score: 92
            },
            lipsNoseRatio: {
                lipLength: 52,
                noseLength: 48,
                ratio: 1.08,
                comparedToAverage: '+3%',
                score: 88
            },
            facialFat: {
                percentage: 12,
                score: 90
            }
        }
    },
    { 
        id: 2, 
        images: [{ url: 'https://picsum.photos/200/301' }], 
        is_processing: true, 
        error_processing: false, 
        createdAt: '2024-03-16'
    },
    { 
        id: 3, 
        images: [{ url: 'https://picsum.photos/200/302' }], 
        is_processing: false, 
        error_processing: true, 
        createdAt: '2024-03-17'
    },
    {
        id: 4,
        images: [{ url: 'https://picsum.photos/200/303' }],
        is_processing: false,
        error_processing: false,
        createdAt: '2024-03-18',
        analysisDetails: {
            canthalTilt: {
                leftEyeAngle: 4.5,
                rightEyeAngle: 4.3,
                averageAngle: 4.4,
                symmetry: 90,
                classification: 'Hunter',
                score: 82
            },
            fwhr: {
                facialWidth: 145,
                facialHeight: 185,
                ratio: 0.784,
                comparedToAverage: '+8%',
                score: 85
            },
            philtumChinRatio: {
                philtumLength: 14,
                chinLength: 44,
                ratio: 0.318,
                comparedToAverage: '-3%',
                score: 88
            },
            lipsNoseRatio: {
                lipLength: 53,
                noseLength: 50,
                ratio: 1.06,
                comparedToAverage: '+2%',
                score: 91
            },
            facialFat: {
                percentage: 14,
                score: 85
            }
        }
    }
];

// Helper function to calculate a simulated progress percentage based on time elapsed
const calculateProgressPercentage = (startTime) => {
    // 60 second estimate for processing time
    const ESTIMATED_SECONDS = 60;
    const now = new Date();
    const elapsedMs = now - new Date(startTime);
    const elapsedSeconds = elapsedMs / 1000;
    const percentage = Math.min(Math.floor((elapsedSeconds / ESTIMATED_SECONDS) * 100), 95);
    
    // Always return at least 5%, max 95% (the final 5% happens when processing completes)
    return Math.max(5, percentage);
};

const AnalysisFeed = ({ analysisResults, onAnalyzeClick, onSelectAnalysis, isLoading, refreshAnalysis }) => {
    // Calculate progress percentage for processing analyses
    const analysisWithProgress = analysisResults.map(result => {
        if (result.is_processing) {
            return {
                ...result,
                processingPercentage: calculateProgressPercentage(result.created_at)
            };
        }
        return result;
    });

    return (
        <>
            <div style={{
                padding: '15px 5px',
                boxSizing: 'border-box',
                zIndex: 1,
                fontSize: '25px',
                fontWeight: 'bold',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
            }}>
                Facial Analysis
            </div>

            <FeedContainer style={{ maxWidth: '600px', margin: '0 auto' }}>
                {isLoading ? (
                    <div style={{
                        textAlign: 'center',
                        color: 'rgb(170,170,170)',
                        fontSize: '20px',
                        marginTop: '20px',
                        maxWidth: '80%',
                        margin: '20px auto 0',
                    }}>
                        Loading analyses...
                    </div>
                ) : (
                    <>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            flexWrap: 'wrap',
                            rowGap: '40px',
                            paddingTop: '30px',
                        }}>
                            {analysisWithProgress.map(result => (
                                <FeedCard
                                    key={result._id}
                                    image_url={result.images[0].url}
                                    isProcessing={result.is_processing}
                                    processingPercentage={result.processingPercentage || 0}
                                    errorProcessing={result.error_processing}
                                    date={formatDateString(result.created_at)}
                                    onClick={() => {
                                        if (result.is_processing) {
                                            // If still processing, refresh the data
                                            refreshAnalysis(result._id);
                                        } else if (!result.error_processing) {
                                            // Only trigger the click handler if the analysis is not processing and has no errors
                                            onSelectAnalysis(result);
                                        }
                                    }}
                                />
                            ))}
                        </div>
                        
                        {analysisResults.length === 0 && (
                            <div style={{
                                textAlign: 'center',
                                color: 'rgb(170,170,170)',
                                fontSize: '20px',
                                marginTop: '20px',
                                maxWidth: '80%',
                                margin: '20px auto 0',
                            }}>
                                No facial analyses found. Create your first analysis!
                            </div>
                        )}
                    </>
                )}
            </FeedContainer>

            <FloatingActionButton onClick={onAnalyzeClick}>
                Create New Analysis
            </FloatingActionButton>
        </>
    );
};

const MeasureScreen = ({ userID }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [analysisResults, setAnalysisResults] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showingResult, setShowingResult] = useState(false);
    const [chosenAnalysisData, setChosenAnalysisData] = useState(null);
    const [hasBoughtBefore, setHasBoughtBefore] = useState(false);
    const [group, setGroup] = useState('A');
    

    const user = useSelector(selectCurrentUser);
    useEffect(() => {
        getCreditHistory().then(history => {
            console.log('history: ', history)
            const onlyPositiveTransactions = history.transactions.filter(transaction => transaction.amount > 0);
            setHasBoughtBefore(onlyPositiveTransactions.length > 0 || user.credits > 0);
        });
    }, [user.credits]);


    useEffect(() => {
        // Use userID as seed to consistently assign the same user to the same group
        const seed = userID ? parseInt(userID.replace(/\D/g, '') || '0', 10) : 0;
        
        // Simple hash function to get a number from the seed
        const hash = seed % 2;
        
        // Assign group based on hash value
        const assignedGroup = hash === 0 ? 'A' : 'B';
        setGroup(assignedGroup);
        
        console.log('userID: ', userID);
        console.log('assignedGroup: ', assignedGroup);

        console.log(`User assigned to group: ${assignedGroup}`);
    }, [userID]);



    // Load all analyses
    const fetchAnalyses = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await getUserAnalyses();
            
            if (response && response.analyses) {
                setAnalysisResults(response.analyses);
            } else {
                // If we get no response or no analyses, set empty array
                setAnalysisResults([]);
            }
        } catch (error) {
            console.error('Error fetching analyses:', error);
            dispatch(setApiError({ 
                message: 'Failed to load facial analyses',
                endpoint: '/api/analyses'
            }));
            // Use dummy data when API fails in development
            if (process.env.NODE_ENV === 'development') {
                setAnalysisResults(dummyAnalysis);
            } else {
                setAnalysisResults([]);
            }
        } finally {
            setIsLoading(false);
        }
    }, [dispatch]);

    // Refresh a specific analysis by ID
    const refreshAnalysis = useCallback(async (analysisId) => {
        try {
            const updatedAnalysis = await getAnalysisById(analysisId);
            
            if (updatedAnalysis) {
                setAnalysisResults(prev => 
                    prev.map(item => 
                        item._id === analysisId ? updatedAnalysis : item
                    )
                );
            }
        } catch (error) {
            console.error(`Error refreshing analysis ${analysisId}:`, error);
        }
    }, []);

    // Fetch analyses on component mount
    useEffect(() => {
        fetchAnalyses();
    }, [fetchAnalyses]);

    // Set up polling for analyses that are still processing
    useEffect(() => {
        const processingAnalyses = analysisResults.filter(analysis => analysis.is_processing);
        
        if (processingAnalyses.length === 0) {
            return; // No processing analyses, no need to poll
        }
        
        // Set up polling interval
        const intervalId = setInterval(() => {
            processingAnalyses.forEach(analysis => {
                refreshAnalysis(analysis._id);
            });
        }, 1000); // Poll every 5 seconds
        
        // Clean up interval on unmount
        return () => clearInterval(intervalId);
    }, [analysisResults, refreshAnalysis]);

    const handleStartNewAnalysis = () => {
        // Navigate to the image upload screen with a parameter indicating this is for facial analysis
        navigate('/upload?type=facial-analysis');
    };

    const handleSelectAnalysis = (analysisData) => {
        setChosenAnalysisData(analysisData);
        setShowingResult(true);
    };

    return (
        <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'stretch',
            overflowY: 'hidden',
            position: 'relative',
            minHeight: 1,
        }}>
            {!showingResult ? (
                <AnalysisFeed
                    analysisResults={analysisResults}
                    onAnalyzeClick={handleStartNewAnalysis}
                    onSelectAnalysis={handleSelectAnalysis}
                    isLoading={isLoading}
                    refreshAnalysis={refreshAnalysis}
                />
            ) : (
                <AnalysisResult
                    analysisData={chosenAnalysisData}
                    hasBoughtBefore={group === 'A' ? hasBoughtBefore : true}
                    onClose={() => {
                        setShowingResult(false);
                        setChosenAnalysisData(null);
                        // Refresh analyses when closing the result view
                        fetchAnalyses();
                    }}
                />
            )}
        </div>
    );
}

export default MeasureScreen;