import React, { useEffect, useRef, useMemo, useState } from 'react';
import LoadingThingie from '../common/LoadingThingie';
import { RxCross1 } from 'react-icons/rx';
import FeedCard from './FeedCard';

import { getBundleInfo, createPslGodBundleVideo, createVideoForSpecificMedia, deleteBundle } from '../../services/api';
import { transformBundle } from '../../utils/bundleTransforms';
import { formatDateString, getSecondsSince } from '../../utils/UtilFuncs';

const BundleCard = ({ 
    bundle, 
    setChosenBundleData, 
    setShowingResult,
}) => {
    const dateString = useMemo(() => formatDateString(bundle.createdAt), [bundle.createdAt]);
    const [progress, setProgress] = useState(0);
    const [bundleData, setBundleData] = useState(bundle);

    const isProcessing = bundleData.is_processing;
    const errorProcessing = bundleData.error_processing;
    const bundleType = bundleData.type;

    const time_to_wait_seconds = bundleType == 1 ? 60 : bundleType == 3 ? 180 : (bundleType === 0 ? 60 : 10000);


    // Add polling logic using useEffect
    useEffect(() => {
        let intervalId;

        const pollBundleStatus = async () => {
            try {
                console.log('Polling bundle status for', bundleData);
                const updatedBundleRaw = await getBundleInfo(bundleData.id);
                const updatedBundle = transformBundle(updatedBundleRaw);
                setBundleData(updatedBundle);

                // If bundle is no longer processing, clear the interval
                if (!updatedBundle.is_processing) {
                    clearInterval(intervalId);
                }
            } catch (error) {
                console.error('Error polling bundle status:', error);
                // Clear interval on error to prevent continuous failed requests
                clearInterval(intervalId);
            }
        };

        // Only start polling if bundle is processing
        if (bundleData.is_processing) {
            // Initial check
            pollBundleStatus();
            // Set up interval for subsequent checks
            intervalId = setInterval(pollBundleStatus, 5000);
        }

        // Cleanup function to clear interval when component unmounts
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [bundleData.id, bundleData.is_processing]);


    useEffect(() => {
        let interval;

        if (isProcessing) {
            interval = setInterval(() => {
                const elapsedSeconds = getSecondsSince(bundleData.createdAt);
                const newProgress = 1 - (Math.max(
                    0,
                    Math.min(100000, time_to_wait_seconds - elapsedSeconds)
                ) / time_to_wait_seconds);

                setProgress(newProgress);
            }, 1000); // Update every second
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isProcessing, time_to_wait_seconds, bundleData]);

    const handleClick = () => {
        if (isProcessing || errorProcessing) return;
        setChosenBundleData(bundleData);
        setShowingResult(true);
    };

    return (
        <FeedCard
            image_url={bundleData.images[0].url}
            isProcessing={isProcessing}
            processingPercentage={Math.round(progress * 100)}
            errorProcessing={errorProcessing}
            date={dateString}
            onClick={handleClick}
        />
    );
}

const Feed = ({
    bundles,
    setReloadBundles,
    isSubscribed,
    setChosenBundleData,
    setShowingResult,
    hasBoughtBefore
}) => {
    const feedRef = useRef(null);

    useEffect(() => {
        if (feedRef.current) {
            // Using smooth scroll behavior for animation
            feedRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, [bundles]); // This effect runs whenever bundles changes

    return (
        <div
            ref={feedRef}
            style={{
                width: '100%',
                maxWidth: '600px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
                rowGap: '40px',
                paddingTop: '30px',
                // alignItems: 'stretch',
                // overflow:'scroll',
            }}
        >
            {
                bundles.map((bundle) => (
                    <BundleCard
                        key={bundle.id}
                        bundle={bundle}
                        setReloadBundles={setReloadBundles}
                        isSubscribed={isSubscribed}
                        setChosenBundleData={setChosenBundleData}
                        setShowingResult={setShowingResult}
                        hasBoughtBefore={hasBoughtBefore}
                    />
                ))
            }

            {/* {
                bundles.map((bundle) => (
                    <BundleCard
                        key={bundle.id}
                        bundle={bundle}
                        setReloadBundles={setReloadBundles}
                        isSubscribed={isSubscribed}
                    />
                ))
            } */}
        </div>
    );
};

export default Feed;