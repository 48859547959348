import React, { useState, useRef, useEffect } from "react";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import {
  Container,
  ProgressBarContainer,
  ProgressStep,
  StepContainer,
  GenderOption,
  GenderImage,
  NextButton,
  LogoContainer,
  StarContainer,
  Star,
  MotivationalText,
  ReferralInputContainer,
  ReferralInput,
  SecondaryText,
  CameraContainer,
  CameraWindow,
  CaptureButton,
  UploadButton,
  SubTitle,
  ButtonContainer,
} from "./OnboardingScreen.styles";
import { PiArrowArcRightLight } from "react-icons/pi";

import { useNavigate } from "react-router-dom";

import "../google.css";
import { authenticateWithGoogle, handleConnectivityError } from '../services/api';
import { color_dict } from "../utils/ColorDict";
import { Button } from "../components/common/Button.styles";
import LooxerButton from "../components/common/Button";
import WebappContainer from "../components/common/WebappContainer";

const Welcome = ({ }) => {
  const [randomTagline, setRandomTagline] = useState(null);

  useEffect(() => {
    const newRandomNumber = Math.floor(Math.random() * 3);
    setRandomTagline(newRandomNumber);
  }, [])


  return (
    <div style={{
      // backgroundColor:'red',

      flex: '1 0 0%',

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',

      gap: '15px',
      boxSizing: 'border-box',
    }}>

      <div style={{
        height: '170px',

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'end',

      }}>
        <div style={{
          // backgroundColor:'red',
          display:'flex',
          flexDirection:'row',
          justifyContent:'center',
          alignItems:'center',
          gap: '10px',

          paddingLeft:'30px',
          boxSizing:'border-box',

          overflow:'hidden',
        }}>
          <video autoPlay
            loop
            muted
            playsInline

            style={{
              width: '150px',
              // transform:'translateY(5px)',
            }}
          >
            <source src="/images/alpha_logo.webm" />
          </video>

          <div style={{
            // backgroundColor: 'red',
            
            fontSize: '22px',
            textAlign: 'start',
            fontWeight: '200'
          }}>

            {
              (false) &&
              (<>What would you look like as a <span style={{ fontWeight: '900' }}>mogger</span>?</>)
            }

            {
              (true) &&
              (<>See your full <span style={{ fontWeight: '900' }}>looksmaxxing potential</span></>)
            }

            {
              (false) &&
              (<>Find your <span style={{ fontWeight: '900' }}>inner light.</span></>)
            }


          </div>
        </div>
      </div>
      <div style={{
        // backgroundColor:'red',
        width: '300px',
        height: '250px',
        // flex: '1 0 0%',
        position: 'relative',
      }}>
        <img src="https://looxer-media-dev.s3.amazonaws.com/user_media/67575626e838f5feabd1942e/83r20npNLevwQzcxx5dxaqLmpPnxljNH/67575626e838f5feabd1942e_1734036701.006354.png" style={{
          position: 'absolute',

          width: '120px',

          transform: 'translateX(-50%) translateY(-50%) rotate(-10deg)',

          top: '30%',
          left: '20%',

        }} />

        <img src="https://looxer-media-dev.s3.amazonaws.com/user_media/67575626e838f5feabd1942e/3bUho3baiMkDRvPjLY5BToQQp6WkweBc/67575626e838f5feabd1942e_1734036707.374044.png" style={{
          position: 'absolute',

          width: '120px',

          transform: 'translateX(-50%) translateY(-50%) rotate(10deg)',

          top: '30%',
          left: '80%',

        }} />

        <PiArrowArcRightLight size="35px" style={{
          position: 'absolute',
          top: '20%',
          left: '45%',
          transform: 'translateX(-50%) translateY(-50%) rotate(20deg)',
        }} />

        <video src="https://looxer-media-dev.s3.amazonaws.com/user_media/67575626e838f5feabd1942e/drVCsvtdpSdGnuE4R6KZGkXCNlUOEFF1/67575626e838f5feabd1942e_1734048581.576298.mp4" autoPlay
          loop
          muted
          playsInline
          style={{
            position: 'absolute',

            width: '140px',

            transform: 'translateX(-50%) translateY(-50%)',

            top: '70%',
            left: '50%',

          }}
        />
      </div>
    </div>
  )
}

const ZeroToHundredMorphCardIcon = ({ psl_analysis }) => {
  const [currImIndex, setCurrImIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrImIndex((ii) => (ii >= 4 ? 0 : ii + 1));
    }, 1000);

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  const max_ims = psl_analysis ? 5 : 3;

  const stage_ims_urls = [
    '/images/0_to_100_ex/0.png',
    '/images/0_to_100_ex/2.png',
    '/images/0_to_100_ex/1.png',
    '/images/0_to_100_ex/3.png',
    '/images/0_to_100_ex/4.png',
  ];


  const psl_analysis_stage_ims_urls = [
    '/images/psl_analysis/2.png',
    '/images/psl_analysis/1.png',
    '/images/psl_analysis/3.png'
  ];

  return (
    <div style={{
      // backgroundColor: 'red',
      position: 'absolute',
      width: '110%',
      aspectRatio: 1,
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
    }}>

      {
        (psl_analysis ? psl_analysis_stage_ims_urls : stage_ims_urls).map((item, index) => (
          <img alt={item} src={item} key={index} style={{
            width: psl_analysis ? '130%' : '110%',
            position: 'absolute',
            top: psl_analysis ? '50%' : '60%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',

            zIndex: index + 1,
            opacity: (index <= currImIndex ? 1 : 0),

            transition: '0.2s all'
          }} />
        ))
      }

    </div>
  )
}

const ModelMogVideoIcon = () => {
  return (
    <video src="https://looxer-media-dev.s3.amazonaws.com/user_media/67575626e838f5feabd1942e/drVCsvtdpSdGnuE4R6KZGkXCNlUOEFF1/67575626e838f5feabd1942e_1734048581.576298.mp4" autoPlay
      loop
      muted
      playsInline
      style={{
        position: 'absolute',

        width: '110%',

        transform: 'translateX(-50%) translateY(-50%)',

        top: '50%',
        left: '50%',

      }}
    />
  )
}

const FeatureCard = ({ setSelectedFavoriteFeature, selectedFavoriteFeature, featureName }) => {

  const selected = selectedFavoriteFeature == featureName;

  return (
    <div style={{
      backgroundColor: selected ? color_dict.logo_purple : color_dict.lighterBackgroundColor,
      width: '100%',
      height: '100px',

      borderRadius: '10px',

      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'stretch',

      gap: '10px',

      transform: `scale(${selected ? 1.1 : 1})`,
      transition: '0.2s all',

      overflow: 'hidden',

      padding: '12px',
      boxSizing: 'border-box',
    }}
      onClick={() => {
        setSelectedFavoriteFeature(featureName)
      }}
    >

      <div style={{
        // backgroundColor: 'red',
        height: '100%',
        aspectRatio: 1,

        overflow: 'hidden',
        position: 'relative',

        borderRadius: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
      }}>
        {featureName == "0_100_morphs" && <ZeroToHundredMorphCardIcon />}
        {featureName == "model_mog_video" && <ModelMogVideoIcon />}
        {featureName == "psl_analysis" && <ZeroToHundredMorphCardIcon psl_analysis={true} />}
      </div>

      <div style={{
        // backgroundColor:'red',
        flex: '1 0 0%',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'start',

        gap: '5px',
      }}>
        <div style={{
          fontSize: '15px',
          fontWeight: 'bold',
        }}>
          {
            featureName === "0_100_morphs" && "0% to 100% Morph"
          }
          {
            featureName === "model_mog_video" && "Model Mog Videos"
          }
          {
            featureName === "psl_analysis" && "PSL Analysis"
          }
        </div>

        <div style={{
          fontSize: '12px',
          fontWeight: '200',
          color: 'rgb(200,200,200)'
        }}>
          {
            featureName === "0_100_morphs" && (
              <>
                See what you'd look like with improved features.
                Less facial fat, better eyebrows, hair, jawline and more
              </>
            )
          }
          {
            featureName === "model_mog_video" && (
              <>
                Generate videos of yourself as a maxxed out PSL God blinded by the flashing lights.
              </>
            )
          }
          {
            featureName === "psl_analysis" && (
              <>
                Full detailed analysis of all facial features. Ramus length, Maxilla width, Canthal tilt angle, etc.
                {/* <div style={{fontSize:'8px'}}>(Not made up like in other apps and comes with actual reccomendations for improvement)</div> */}
              </>
            )
          }

        </div>
      </div>

    </div>
  )
}

const FavoriteFeature = ({ setSelectedFavoriteFeature, selectedFavoriteFeature }) => {
  return (
    <div style={{
      // backgroundColor:'red',

      flex: '1 0 0%',

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',

      gap: '15px',
      boxSizing: 'border-box',
    }}>

      <div style={{
        fontSize: '26px',
        textAlign: 'start',
        fontWeight: '200'
      }}>
        Which feature are you most interested in?
      </div>

      <FeatureCard selectedFavoriteFeature={selectedFavoriteFeature} setSelectedFavoriteFeature={setSelectedFavoriteFeature}
        featureName="0_100_morphs" />
      <FeatureCard selectedFavoriteFeature={selectedFavoriteFeature} setSelectedFavoriteFeature={setSelectedFavoriteFeature}
        featureName="model_mog_video" />
      <FeatureCard selectedFavoriteFeature={selectedFavoriteFeature} setSelectedFavoriteFeature={setSelectedFavoriteFeature}
        featureName="psl_analysis" />

    </div>
  )
}

const GenderCard = ({ selected, title, image, onClick, flip }) => {
  return (
    <div style={{
      backgroundColor: selected ? color_dict.logo_purple : color_dict.lighterBackgroundColor,
      width: '150px',
      height: '200px',

      borderRadius: '10px',

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',

      transform: `scale(${selected ? 1.1 : 1})`,
      transition: '0.2s all',

      fontWeight: '700',
      overflow: 'hidden',

      boxSizing: 'border-box',
    }}
      onClick={onClick}
    >
      <div style={{
        padding: '10px',

      }}>{title}</div>

      <div style={{
        // backgroundColor: 'red',
        width: '100%',
        flex: '1 0 0%',

        position: 'relative'
      }}>

        <img src={image} style={{
          opacity: selected ? 1 : 0.8,
          width: '110%',

          position: 'absolute',
          left: '50%',
          bottom: '-10px',

          transform: 'translateX(-50%) scaleX(-1)',

          zIndex: 1,

          WebkitMaskImage: 'linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 95%)',
          maskImage: 'linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 95%)',

          WebkitMaskSize: '100% 100%',
          maskSize: '100% 100%',

        }} />
      </div>
    </div>
  )
}

const GenderChoice = ({ setSelectedGender, selectedGender }) => {

  return (
    <div style={{
      // backgroundColor:'red',

      flex: '1 0 0%',

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
    }}>

      <div style={{
        // backgroundColor:'green',
        fontSize: '30px',
      }}>
        Select Gender
      </div>

      <div style={{
        // backgroundColor:'red',
        flex: '1 0 0%',


        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center'
      }}>
        <GenderCard title="Chad" image="/images/chad1.png" flip={true} selected={selectedGender == 'male'} onClick={() => setSelectedGender("male")} />
        <GenderCard title="Stacy" image="/images/chadgirl1.png" selected={selectedGender == 'female'} onClick={() => setSelectedGender("female")} />
      </div>

    </div>
  )
}


const TOTAL_STEPS = 4;
const SignInWithGoogle = () => {
  const handleSuccess = async (credentialResponse) => {
    try {
      const data = await authenticateWithGoogle(credentialResponse.credential);
      console.log('Logged in user:', data.user);
      window.location.href = '/';
    } catch (error) {
      if (error.message.includes("Network Error")) {
        handleConnectivityError();
      }
    }
  };

  return (
    <div style={{ flex: "0 1", alignItems: 'center', justifyContent: 'center' }}>
      <GoogleLogin
        onSuccess={handleSuccess}
        onError={() => {
          console.log('Login Failed');
        }}
        useOneTap
        shape="pill"
        size="large"
      />
    </div>
  );
};

const OnboardingScreen = () => {

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedFavoriteFeature, setSelectedFavoriteFeature] = useState(null);
  const [referralCode, setReferralCode] = useState("");
  const [alreadyHaveAccount, setAlreadyHaveAccount] = useState(false);

  const handleNext = () => {
    if (currentStep === 0) {
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 1 && selectedGender) {
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 2) {
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 3) {
      console.log('Completing sign in...');
    }
  };


  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <Welcome />
        )
      case 1:
        return (
          <GenderChoice
            setSelectedGender={setSelectedGender}
            selectedGender={selectedGender}
          />
        );
      // case 2:
      //   return (
      //     <>
      //       <div style={{ flex: 1 }}>&nbsp;</div>
      //       <MotivationalText style={{ marginBottom: "8px" }}>Over 15,000 people already joined Looxer</MotivationalText>
      //       <SubTitle style={{ marginBottom: "30px" }}>Looxer will generate photos and videos of you as a model, and help you track your progress there.</SubTitle>
      //       <LogoContainer>
      //         {/* Replace with your actual logo */}
      //         <img src="/images/looxer.png" alt="Logo" />
      //       </LogoContainer>
      //       <StarContainer>
      //         {[...Array(5)].map((_, index) => (
      //           <Star key={index} src="/images/star.png" alt="Star" />
      //         ))}
      //       </StarContainer>
      //       <div style={{ flex: 1 }}>&nbsp;</div>
      //     </>
      //   );
      case 2:
        return (<FavoriteFeature setSelectedFavoriteFeature={setSelectedFavoriteFeature} selectedFavoriteFeature={selectedFavoriteFeature} />)
      case 3:
        return (
          <>
            <div style={{ flex: 0.7 }}>&nbsp;</div>
            <div style={{

              width: '100%',
              textAlign: 'center',
              fontSize: '26px',
              fontWeight: '900',

              display: 'flex',
              justifyContent: 'center',
            }}>{alreadyHaveAccount ? "SIGN IN WITH GOOGLE" : "CREATE YOUR ACCOUNT"}</div>
            <SubTitle>You are one screen away from unlocking your full looksmaxxing potential</SubTitle>
            {/* <br /><br /><br /> */}
            <div style={{
              // backgroundColor:'red',
              display: 'flex',
              justifyContent: 'center',
              transform: 'scale(1.2)',

              marginTop: '40px',
            }}>
              <SignInWithGoogle />
            </div>
            <div style={{ flex: 1 }}>&nbsp;</div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <GoogleOAuthProvider clientId="31797981954-cj390ehr2fidhbhlf26dbqrucpmlu9tg.apps.googleusercontent.com">
      <WebappContainer>
        <div style={{
          background: `linear-gradient(to bottom, rgb(0, 0, 0, 1) 0%,
rgb(0, 0, 0, 1) 5%,
rgb(0, 0, 0, 0) 30%,
rgb(0, 0, 0, 0) 70%,
rgb(0, 0, 0, 1) 99%,
rgb(0, 0, 0, 1) 100%
          )`,
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
        }}>
        </div>

        <ProgressBarContainer>
          {Array.from({ length: TOTAL_STEPS }).map((_, index) => (
            <ProgressStep
              key={index}
              $isActive={index <= currentStep}
              $isCompleted={index + 1 < currentStep}
            />
          ))}

        </ProgressBarContainer>

        <StepContainer>
          {
            renderStep()
          }
        </StepContainer>

        {currentStep !== 3 && (
          <>
            <ButtonContainer>

              <LooxerButton
                onClick={handleNext}
                text="Continue"
                active={!(currentStep === 1 && !selectedGender) && !(currentStep === 2 && !selectedFavoriteFeature)}
              />

              <div
                onClick={() => {
                  setCurrentStep(3);
                  setAlreadyHaveAccount(true);
                }}
                style={{
                  color: 'white',
                  cursor: 'pointer',
                  fontSize: '14px'
                }}
              >
                I already have an account
              </div>
            </ButtonContainer>
          </>
        )}
        <div style={{
          // backgroundColor:'red',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',

          gap: '20px',
          padding: '10px',

          fontSize: '12px',
          color: 'gray',
          zIndex: 2,
        }}>
          <div onClick={() => { window.location.href = "/tos.txt"; }}>Terms of service</div>
          <div onClick={() => { window.location.href = "/privacy.txt"; }}>Privacy Policy</div>
          <div onClick={() => { window.location.href = "/refunds.txt"; }}>Refund Policy</div>
        </div>
      </WebappContainer>
    </GoogleOAuthProvider>
  );
};

export default OnboardingScreen; 