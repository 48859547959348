import { useState, useEffect } from "react";
import WebappContainer from '../components/common/WebappContainer';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../features/auth/authSlice';

import DashboardScreen from "./DashboardScreen";
import UserProfile from "./UserProfile";
import GuidesScreen from "./GuidesScreen";
import { FaPencilAlt, FaBook, FaUser, FaRuler } from "react-icons/fa";
import {
    TopBar,
    IconButton,
} from './DashboardScreen.styles';


import MeasureScreen from "./MeasureScreen";

const NavBar = ({ activeSection, setActiveSection }) => {
    // Check if beta=true is in the URL
    const urlParams = new URLSearchParams(window.location.search);
    const isBeta = urlParams.get('beta') === 'true';

    // Use the 4-value list if beta=true, otherwise use the 2-value list
    const labels = isBeta
        ? ["Measure", "Workshop", "Info", "Profile"]
        : ["Measure", "Workshop", "Profile"];


    return (
        <div style={{
            width: '100%',
            flex: '0 0 60px',
            // background: 'blue',
            zIndex: 1,

            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',

            padding: '5px 10px',
            boxSizing: 'border-box',

            position: 'relative',

            minHeight: 1,
        }}>
            {
                labels.map((label, index) => (
                    <div key={index} style={{
                        // background: 'red',
                        height: '100%',
                        aspectRatio: 1,
                        color: label.toLowerCase() === activeSection ? 'white' : 'rgb(170,170,170)',

                        overflow: 'hidden',

                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '5px',

                        fontSize: '10px',
                        fontWeight: 'bold',

                        cursor: 'pointer',

                    }} onClick={() => setActiveSection(label.toLowerCase())}>
                        {
                            label === "Measure" &&
                            <FaRuler size={15} />
                        }
                        {
                            label === "Workshop" &&
                            <FaPencilAlt size={15} />
                        }
                        {
                            label === "Info" &&
                            <FaBook size={15} />
                        }
                        {
                            label === "Profile" &&
                            <FaUser size={15} />
                        }
                        {label === "Measure" && "Analysis"}
                        {label === "Workshop" && "Morphs"}
                        {label !== "Measure" && label !== "Workshop" && label}
                    </div>
                ))}
        </div>
    )
}


const MainContent = ({ isSubscribed, setReloadUser }) => {
    const [chosenSection, setChosenSection] = useState(() => {
        // Try to get the saved section from localStorage
        const savedSection = localStorage.getItem('chosenSection');
        // Return the saved section if it exists, otherwise randomly choose between "measure" and "workshop"
        if (savedSection) {
            return savedSection;
        } else {
            // Randomly select between "measure" and "workshop"
            const options = ["measure", "workshop"];
            const randomIndex = Math.floor(Math.random() * 2);
            return options[randomIndex];
        }
    });
    
    const user = useSelector(selectCurrentUser);

    // Save to localStorage whenever chosenSection changes
    useEffect(() => {
        localStorage.setItem('chosenSection', chosenSection);
    }, [chosenSection]);
    
    // Log the user state when it changes
    useEffect(() => {
        console.log('Current user state:', user);
    }, [user]);

    return (
        <WebappContainer style={{
            minHeight: 1,
        }}>
            <div style={{
                background: `linear-gradient(to bottom, rgb(0, 0, 0, 1) 0%,
rgb(0, 0, 0, 1) 5%,
rgb(0, 0, 0, 0) 30%,
rgb(0, 0, 0, 0) 70%,
rgb(0, 0, 0, 1) 99%,
rgb(0, 0, 0, 1) 100%
      )`,
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                zIndex: 0,
            }}>
            </div>


            <TopBar>
                <div style={{
                    height: '80%',
                    aspectRatio: 2,
                    position: 'relative',
                    overflow: 'hidden',
                }}>
                    <video autoPlay
                        loop
                        muted
                        playsInline

                        style={{
                            // width: '100px',
                            height: '100%',
                            // transform:'translateY(5px)',
                        }}
                    >
                        <source src="/images/alpha_logo.webm" />
                    </video>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '20px',
                }}>
                    {chosenSection === "profile" &&
                        <div style={{
                            fontSize: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '5px',
                            textAlign: 'center',
                            color: 'rgb(170,170,170)'
                        }}>
                            Encountered an issue? <br />
                            Reach us at: <span style={{ color: 'white' }}>looxer.ai@gmail.com</span>
                        </div>
                    }
                </div>
            </TopBar>

            <div style={{
                width: '100%',
                flex: '1 0 0%',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',

                zIndex: 1,
                position: 'relative',

                overflow: 'hidden',
            }}>

                {chosenSection === "measure" &&
                    <MeasureScreen userID={user.id} />
                }

                {chosenSection === "workshop" &&
                    <DashboardScreen isSubscribed={isSubscribed} setReloadUser={setReloadUser} userID={user.id}/>
                }

                {chosenSection === "profile" &&
                    <UserProfile isSubscribed={isSubscribed} setReloadUser={setReloadUser} />
                }

                {chosenSection === "info" &&
                    <GuidesScreen />
                }
            </div>

            <NavBar activeSection={chosenSection} setActiveSection={setChosenSection} />

        </WebappContainer>
    )
}

export default MainContent;