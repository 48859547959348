import { useEffect, useState, useMemo } from 'react';
import { FaCross } from 'react-icons/fa';
import { IoCloseSharp, IoSparklesSharp } from 'react-icons/io5';
import { formatDateString, getSecondsSince } from '../../utils/UtilFuncs';
import { IoMdDownload } from 'react-icons/io';

import { useAsyncError, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import { selectCurrentUser } from '../../features/auth/authSlice';
import { getBundleInfo, createPslGodBundleVideo, createVideoForSpecificMedia, deleteBundle } from '../../services/api';
import { openDialog } from '../../features/dialog/dialogSlice';
import BundleMorphT from './MorphResult_v2';
import LooxerButton from '../common/Button';

const VideoMorph = ({ bundleData, dateString }) => {
    return (
        <div style={{
            minHeight: 1,
            flex: '1 0 0%',
            zIndex: 1,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            gap: '20px',

            padding: '20px 20px',
            paddingBottom: '20px',
            boxSizing: 'border-box',
            overflow: 'hidden',
        }}>
            {/* <div style={{
                fontSize: '25px',
                fontWeight: 'bold',
                textAlign: 'center',
            }}>
                Video: {dateString}
            </div> */}
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '20px',
                paddingTop: '15px',
                boxSizing: 'border-box',
            }}>
                <div style={{
                    fontSize: '22px',
                }}>
                    Your FULL potential.
                </div>
                <div style={{
                    width: '90%',
                    maxWidth: '400px',
                    aspectRatio: '1/1',
                    borderRadius: '20px',
                    overflow: 'hidden',
                    position: 'relative',
                    boxShadow: 'rgb(0, 0, 0) 0px 20px 30px -10px',
                }}>
                    <video src={bundleData.images[1].url} autoPlay loop muted playsInline
                        style={{
                            position: 'absolute',
                            width: '100%',
                            borderRadius: '10px',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%) scale(1.05)',
                        }} />

                    <div style={{
                        backgroundColor: 'white',
                        position: 'absolute',
                        bottom: '10px',
                        left: '10px',

                        padding: '3px 10px',

                        color: 'black',
                        fontSize: '16px',
                        fontWeight: '600',

                        borderRadius: '20px',
                        cursor: 'pointer',

                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '5px',
                    }}
                        onClick={() => {
                            const link = document.createElement('a');
                            link.href = bundleData.images[1].url;
                            link.target = '_blank';
                            link.download = 'morph_result.mp4';
                            link.click();
                        }}
                    >
                        <IoMdDownload style={{ transform: 'translateY(2px)' }} />
                        Save
                    </div>
                </div>
            </div>
        </div>
    )
}

const CreateVideoButton = ({ image, setReloadBundles, onCreateVideo, isCreatingPSLVideo, setIsCreatingPSLVideo }) => {
    const { credits } = useSelector(state => state.auth.user || { credits: 0 });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCreatePslVideo = async (image) => {

        const UPLOAD_COST = 5; // Credits cost for upload
        const remainingCredits = credits - UPLOAD_COST;

        if (credits < UPLOAD_COST) {
            dispatch(openDialog({
                title: 'Insufficient Credits',
                message: `You need ${UPLOAD_COST} credits to create a video. You currently have ${credits} credits.`,
                confirmText: 'Get Credits',
                cancelText: 'Cancel',
                onConfirm: () => {
                    navigate('/more-credits'); // TODO: notice here
                    // window.open('https://looxer.gumroad.com/l/svsvt', '_blank');
                }
            }));
            return;
        }


        dispatch(openDialog({
            title: 'Confirm Creation',
            message: `Creating a video costs ${UPLOAD_COST} credits. You will have ${remainingCredits} credits remaining.`,
            confirmText: 'Create',
            cancelText: 'Cancel',
            onConfirm: async () => {
                let image_id = null;

                image_id = image.id;

                // setIsCreatingPsl(true);
                setIsCreatingPSLVideo(true);
                try {
                    const result = await createVideoForSpecificMedia(image_id);
                    // You might want to handle the successful creation here
                    // For example, showing a success message or updating the UI
                } catch (error) {
                    console.error('Failed to create PSL God bundle video:', error);
                    // You might want to show an error message to the user
                } finally {
                    // setIsCreatingPsl(false);
                    setIsCreatingPSLVideo(false);
                    setTimeout(onCreateVideo, 500);
                }
            }
        }));
    };

    return (
        <div style={{
            opacity: isCreatingPSLVideo ? 0.5 : 1,
            backgroundColor: 'white',
            // position: 'absolute',
            // bottom: '10px',
            // right: '10px',

            padding: '3px 10px',

            color: 'black',
            fontSize: '16px',
            fontWeight: '600',

            borderRadius: '20px',
            cursor: 'pointer',

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px',

            zIndex: 2,
        }}
            onClick={() => {
                if (isCreatingPSLVideo) return;

                handleCreatePslVideo(image)
            }}
        >
            <IoSparklesSharp />
            Create Video
        </div>
    )
}

const BundleMorph = ({
    bundleData,
    dateString,
    setReloadBundles,
    onCreateVideo,
    hasBoughtBefore,
    group
}) => {
    const navigate = useNavigate();

    const amount_images = 5;
    const [selectedImage, setSelectedImage] = useState(0);
    const [isCreatingPSLVideo, setIsCreatingPSLVideo] = useState(false);


    const image_index_slides = [1, 5, 2, 3, 4];

    const handlers = useSwipeable({
        onSwipedLeft: () => setSelectedImage((prev) => (prev + 1) % amount_images),
        onSwipedRight: () => setSelectedImage((prev) => (prev > 0 ? prev - 1 : 0)),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    return (
        <div style={{
            minHeight: 1,
            flex: '1 0 0%',
            zIndex: 1,

            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            gap: '20px',

            padding: '20px 20px',
            paddingBottom: '20px',
            boxSizing: 'border-box',

            overflowY: 'scroll',
        }}>

            {/* <div style={{
                fontSize: '25px',
                fontWeight: 'bold',
                textAlign: 'center',
            }}>
                Morph: {dateString}
            </div> */}
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '20px',
                paddingTop: '15px',
                boxSizing: 'border-box',

            }}>
                <div style={{
                    fontSize: '22px',
                }}>
                    {
                        selectedImage === 0 && 'Your starting point..'
                    }

                    {
                        selectedImage === 2 && 'Step 1: Getting Leaner'
                    }

                    {
                        selectedImage === 3 && 'Step 2: Eyebrows'
                    }

                    {
                        selectedImage === 4 && 'Step 3: Hair'
                    }

                    {
                        selectedImage === 1 && 'Full Potential'
                    }

                </div>

                <div {...handlers} style={{
                    width: '90%',
                    maxWidth: '400px',
                    aspectRatio: '1/1',
                    borderRadius: '20px',
                    overflow: 'hidden',
                    position: 'relative',
                    boxShadow: 'rgb(0, 0, 0) 0px 20px 30px -10px',
                }}>
                    {
                        image_index_slides.map((image_index, index) => (
                            <img src={bundleData.images[image_index].url} alt="bundle" style={{
                                position: 'absolute',
                                width: '100%',
                                borderRadius: '10px',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%) scale(1.1)',

                                opacity: selectedImage >= index ? 1 : 0,

                                transition: 'opacity 0.5s',
                            }} />
                        ))
                    }

                    {
                        !hasBoughtBefore && selectedImage >= 2 && (
                            <div style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0,0,0,0.5)',

                                position: 'absolute',
                                top: '50%',
                                left: '50%',

                                transform: 'translate(-50%, -50%)',
                            }}>

                                <div style={{
                                    width: '100%',
                                    height: '100%',

                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',

                                    transform: 'translate(-50%, -50%)',

                                    //blur
                                    backdropFilter: 'blur(16px)',
                                    WebkitBackdropFilter: 'blur(16px)',
                                }} />

                                <LooxerButton style={{
                                    position: 'absolute',
                                    width: '60%',
                                    top: '50%',
                                    left: '50%',

                                    transform: 'translate(-50%, -50%)',
                                }}
                                    onClick={() => {
                                        navigate('/more-credits');
                                    }}
                                    text="See your full potential"
                                    active={true}
                                />
                            </div>
                        )
                    }

                    {hasBoughtBefore &&
                        (<div style={{
                            backgroundColor: 'white',
                            position: 'absolute',
                            bottom: '10px',
                            left: '10px',

                            padding: '3px 10px',

                            color: 'black',
                            fontSize: '16px',
                            fontWeight: '600',

                            borderRadius: '20px',
                            cursor: 'pointer',

                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                            onClick={() => {
                                const link = document.createElement('a');
                                link.href = bundleData.images[image_index_slides[selectedImage]].url;
                                link.target = '_blank';
                                link.download = 'morph_result.jpg';
                                link.click();
                            }}
                        >
                            <IoMdDownload style={{ transform: 'translateY(2px)' }} />
                            Save
                        </div>
                        )
                    }
                </div>

                <div style={{
                    // backgroundColor: "red",
                    width: '100%',

                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',

                    padding: '0px 25px',
                    boxSizing: 'border-box',
                }}>
                    <div style={{
                        // backgroundColor:'red',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '12px',
                        // marginTop: '7px',
                        alignSelf: 'flex-start',
                    }}>
                        {Array.from({ length: amount_images }).map((_, index) => (
                            <div key={index} style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                backgroundColor: selectedImage === index ? 'white' : 'gray',
                                cursor: 'pointer',
                            }} onClick={() => setSelectedImage(index)} />
                        ))}
                    </div>

                    <CreateVideoButton
                        setReloadBundles={setReloadBundles}
                        image={bundleData.images[5]}
                        onCreateVideo={onCreateVideo}
                        isCreatingPSLVideo={isCreatingPSLVideo}
                        setIsCreatingPSLVideo={setIsCreatingPSLVideo}
                    />
                </div>
            </div>

            <div style={{
                flex: '1 0 0%',
                backgroundColor: 'rgba(0,0,0,0.5)',
                // backgroundColor:'red',
                borderRadius: '20px',

                padding: '10px 20px',
                boxSizing: 'border-box',

                fontSize: '16px',

                textAlign: 'start',

                overflowY: 'none',
                color: 'rgb(190,190,190)',
            }}>
                {selectedImage === 0 && "Swipe to see your full transformation!"}

                
                {selectedImage === 2 && (
                    hasBoughtBefore ? (
                        <div style={{ textAlign: 'start' }}>
                            The most significant way a guy can transform their looks is by leaning out and debloating the face.
                            <br />
                            <br />
                            This is not as simple as just being in a caloric deficit.
                            <br />
                            <br />
                            Check the utils section of looxer to get a guide on getting leaner quick.
                        </div>
                    ) : (
                        (group === 'A' ? "" : "Purchase any amount of credits to unlock all steps for your full transformation")
                    )
                )}

                {selectedImage === 3 && (
                    hasBoughtBefore ? (
                        <div>
                            Eyebrows are an absolute cheatcode.
                            <br />
                            <br />
                            Use microneedling, minoxidil and castor oil to get thicker eyebrows.
                            Visit looxer's utils section for a full guide on getting eyebrow grooming.
                            <br />
                            <br />
                        </div>
                    ) : (
                        (group === 'A' ? "" : "Purchase any amount of credits to unlock all steps for your full transformation")
                    )
                )}

                {selectedImage === 4 && (
                    hasBoughtBefore ? (
                        <div>
                            With a simple hair routine and a good barber, you will go a long way.
                            Check out the looxer's guide on hair oiling, and finding the perfect hairstyle for you.
                        </div>
                    ) : (
                        (group === 'A' ? "" : "Purchase any amount of credits to unlock all steps for your full transformation")
                    )
                )}

                {selectedImage === 1 && "Your full potential in all its glory!"}
            </div>
        </div>
    )
}

const MorphResult = ({
    chosenBundleData,
    onClose,
    setReloadBundles,
    onCreateVideo,
    hasBoughtBefore,
    group,
    userID
}) => {
    const dateString = useMemo(() => formatDateString(chosenBundleData.createdAt), [chosenBundleData.createdAt]);
    const [useNewMorph, setUseNewMorph] = useState(false);

    const bundleType = chosenBundleData.type;

    useEffect(() => {
        // Use userID as seed to consistently assign the same user to the same group
        const seed = userID ? parseInt(userID.replace(/\D/g, '') || '0', 10) : 0;
        
        // Simple hash function to get a number from the seed
        const hash = (seed+1234) % 2;

        setUseNewMorph(hash === 0);
    }, [userID]);

    useEffect(() => {
        console.log('bundleData', chosenBundleData);
    }, [chosenBundleData]);

    return (
        <div style={{

            width: '100%',
            height: '100%',

            zIndex: 1,

            position: 'relative',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'stretch',

            minHeight: 1,
            boxSizing: 'border-box',

            overflowY: 'scroll',
        }}>

            <IoCloseSharp style={{
                position: 'absolute',
                left: '10px',
                top: '10px',
                fontSize: '30px',
                cursor: 'pointer',
                zIndex: 2,
            }}
                onClick={onClose}
            />
            {
                bundleType === 1 && (
                    (
                        true ? (
                            <BundleMorphT
                                dateString={dateString}
                                bundleData={chosenBundleData}
                                setReloadBundles={setReloadBundles}
                                onCreateVideo={onCreateVideo}
                                hasBoughtBefore={hasBoughtBefore}
                                group={group}
                            />
                        ) : (
                            <BundleMorph
                                dateString={dateString}
                                bundleData={chosenBundleData}
                                setReloadBundles={setReloadBundles}
                                onCreateVideo={onCreateVideo}
                                hasBoughtBefore={hasBoughtBefore}
                                group={group}
                            />
                        )
                    )
                )
            }

            {
                bundleType === 3 && (
                    <VideoMorph
                        dateString={dateString}
                        bundleData={chosenBundleData}
                    />
                )
            }

        </div>
    )
}

export default MorphResult;