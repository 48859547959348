import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdDownload } from 'react-icons/io';
import { IoSparklesSharp } from 'react-icons/io5';
import LooxerButton from '../common/Button';
import { openDialog } from '../../features/dialog/dialogSlice';
import { createVideoForSpecificMedia } from '../../services/api';

const CreateVideoButton = ({ image, setReloadBundles, onCreateVideo, isCreatingPSLVideo, setIsCreatingPSLVideo }) => {
    const { credits } = useSelector(state => state.auth.user || { credits: 0 });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCreatePslVideo = async (image) => {
        const UPLOAD_COST = 5; // Credits cost for upload
        const remainingCredits = credits - UPLOAD_COST;

        if (credits < UPLOAD_COST) {
            dispatch(openDialog({
                title: 'Insufficient Credits',
                message: `You need ${UPLOAD_COST} credits to create a video. You currently have ${credits} credits.`,
                confirmText: 'Get Credits',
                cancelText: 'Cancel',
                onConfirm: () => {
                    navigate('/more-credits');
                }
            }));
            return;
        }

        dispatch(openDialog({
            title: 'Confirm Creation',
            message: `Creating a video costs ${UPLOAD_COST} credits. You will have ${remainingCredits} credits remaining.`,
            confirmText: 'Create',
            cancelText: 'Cancel',
            onConfirm: async () => {
                let image_id = image.id;

                setIsCreatingPSLVideo(true);
                try {
                    const result = await createVideoForSpecificMedia(image_id);
                } catch (error) {
                    console.error('Failed to create video:', error);
                } finally {
                    setIsCreatingPSLVideo(false);
                    setTimeout(onCreateVideo, 500);
                }
            }
        }));
    };

    return (
        <div style={{
            backgroundColor: 'white',
            width: '100%',

            opacity: isCreatingPSLVideo ? 0.5 : 1,

            padding: '12px 10px',
            color: 'black',
            fontSize: '16px',
            fontWeight: '600',
            borderRadius: '15px',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px',
            zIndex: 2,
        }}
            onClick={() => {
                if (isCreatingPSLVideo) return;
                handleCreatePslVideo(image)
            }}
        >
            <IoSparklesSharp />
            Create Video
        </div>
    )
}

const BundleMorphT = ({
    bundleData,
    dateString,
    setReloadBundles,
    onCreateVideo,
    hasBoughtBefore,
    group
}) => {
    const navigate = useNavigate();
    const [sliderValue, setSliderValue] = useState(0);
    const [isCreatingPSLVideo, setIsCreatingPSLVideo] = useState(false);

    const amount_images = 5;
    const image_index_slides = [1, 2, 3, 4, 5];

    // Generate a unique ID for the slider
    const sliderId = "potential-slider";

    // Calculate the selected image index based on slider value
    const selectedImageIndex = Math.min(
        Math.floor(sliderValue / (100 / (amount_images - 1))),
        amount_images - 2
    );

    // Calculate progress within the current range
    const rangeSize = 100 / (amount_images - 1);
    const inRangeProgress = (sliderValue % rangeSize) / rangeSize;

    // Get current and next image indexes
    let currentImageIndex = selectedImageIndex;
    let nextImageIndex = selectedImageIndex + 1;

    // Special case for 100% to ensure it shows the last image
    if (sliderValue >= 99.5) {
        currentImageIndex = amount_images - 1;
        nextImageIndex = amount_images - 1;
    }

    // Get titles based on progress
    let title = '';
    if (sliderValue < 10) {
        title = 'Your starting point..';
    } else if (sliderValue < 50) {
        title = 'Step 1: Getting Leaner';
    } else if (sliderValue < 80) {
        title = 'Step 2: Eyebrows';
    } else if (sliderValue < 95) {
        title = 'Step 3: Hair';
    } else {
        title = 'Full Potential';
    }


    return (
        <div style={{
            minHeight: 1,
            flex: '1 0 0%',
            zIndex: 1,
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            gap: '20px',
            padding: '20px 20px',
            paddingBottom: '20px',
            boxSizing: 'border-box',
            overflowY: 'scroll',
        }}>
            {/* Style for the custom slider to hide browser native thumb */}
            <style>
                {`
                #${sliderId}::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 0;
                    height: 0;
                    background: transparent;
                    border: none;
                }
                #${sliderId}::-moz-range-thumb {
                    width: 0;
                    height: 0;
                    background: transparent;
                    border: none;
                }
                #${sliderId}::-ms-thumb {
                    width: 0;
                    height: 0;
                    background: transparent;
                    border: none;
                }
                `}
            </style>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '20px',
                paddingTop: '15px',
                boxSizing: 'border-box',

            }}>
                <div style={{
                    color: 'white',
                    fontWeight: 'normal',
                    letterSpacing: '1.5px',
                    wordSpacing: '3px',
                    textAlign: 'center',
                    fontSize: '22px',
                }}>
                    {
                        currentImageIndex === 0 && <div>Starting Point</div>
                    }
                    {
                        currentImageIndex === 1 && <div>Chad-lite</div>
                    }
                    {
                        currentImageIndex === 2 && <div>Chad</div>
                    }
                    {
                        currentImageIndex === 3 && <div>PSL-GOD</div>
                    }
                    {
                        currentImageIndex === 4 && <>Your <span style={{ fontWeight: 'bold' }}>Full</span> Potential</>
                    }


                </div>

                <div style={{
                    width: '90%',
                    maxWidth: '400px',
                    aspectRatio: '1/1',
                    borderRadius: '20px',
                    overflow: 'hidden',
                    position: 'relative',
                    boxShadow: 'rgb(0, 0, 0) 0px 20px 30px -10px',
                }}>
                    {image_index_slides.map((image_index, index) => (
                        <img
                            key={index}
                            src={bundleData.images[image_index].url}
                            alt="transformation"
                            style={{
                                position: 'absolute',
                                width: '100%',
                                borderRadius: '10px',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%) scale(1.1)',
                                opacity: index === 0
                                    ? 1 // First image always fully visible
                                    : index < currentImageIndex
                                        ? 1 // Images that were already fully shown stay at full opacity
                                        : index === currentImageIndex
                                            ? 1 - inRangeProgress // Current image fades out
                                            : index === nextImageIndex
                                                ? inRangeProgress // Next image fades in
                                                : 0, // Future images remain hidden
                                // transition: 'opacity 0.2s',
                                zIndex: index, // Add z-index to ensure stacking order
                            }}
                        />
                    ))}

                    {!hasBoughtBefore && sliderValue > 25 && (
                        <div style={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 10,
                        }}>
                            <div style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                backdropFilter: 'blur(16px)',
                                WebkitBackdropFilter: 'blur(16px)',
                            }} />

                            <LooxerButton style={{
                                position: 'absolute',
                                width: '60%',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                                onClick={() => {
                                    navigate('/more-credits');
                                }}
                                text="See your full potential"
                                active={true}
                            />
                        </div>
                    )}

                    {hasBoughtBefore && (
                        <div style={{
                            backgroundColor: 'white',
                            position: 'absolute',
                            bottom: '10px',
                            left: '10px',
                            padding: '3px 10px',
                            color: 'black',
                            fontSize: '16px',
                            fontWeight: '600',
                            borderRadius: '20px',
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '5px',
                            zIndex: 10,
                        }}
                            onClick={() => {
                                // Determine which image to download based on slider value
                                const imageToDownload = Math.min(
                                    Math.round(sliderValue / (100 / (amount_images - 1))),
                                    amount_images - 1
                                );
                                const link = document.createElement('a');
                                link.href = bundleData.images[image_index_slides[imageToDownload]].url;
                                link.target = '_blank';
                                link.download = 'morph_result.jpg';
                                link.click();
                            }}
                        >
                            <IoMdDownload style={{ transform: 'translateY(2px)' }} />
                            Save
                        </div>
                    )}
                </div>

                <div style={{
                    width: '90%',
                    maxWidth: '400px',
                    padding: '0 10px',
                    boxSizing: 'border-box',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                }}>
                    <div style={{
                        fontSize: '16px',
                        color: 'white',
                        fontWeight: 'bold',
                    }}>
                        Potential Slider
                    </div>
                    <div style={{
                        width: '100%',
                        position: 'relative',
                        height: '24px',
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'space-between',
                        // alignItems: 'center',

                        // gap: '10px',
                    }}>

                        <div style={{
                            // backgroundColor: 'white',
                            flex: '1 0 0%',
                            position: 'relative',

                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <div style={{
                                backgroundColor: '#303030',
                                position: 'absolute',
                                width: '100%',
                                height: '7px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                borderRadius: '15px',
                                overflow: 'hidden',
                            }}>
                                <div style={{
                                    backgroundColor: '#A1A1A1',
                                    position: 'absolute',
                                    width: `${sliderValue}%`,
                                    height: '100%',
                                }} />
                            </div>

                            <input
                                id={sliderId}
                                type="range"
                                min="0"
                                max="100"
                                value={sliderValue}
                                onChange={(e) => setSliderValue(Number(e.target.value))}
                                style={{
                                    width: '100%',
                                    height: '24px',
                                    appearance: 'none',
                                    WebkitAppearance: 'none',
                                    background: 'transparent',
                                    outline: 'none',
                                    opacity: '1',
                                    cursor: 'pointer',
                                    position: 'relative',
                                    zIndex: 2,
                                    margin: 0,
                                }}
                            />
                            <div style={{
                                position: 'absolute',
                                width: '18px',
                                height: '18px',
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                top: '50%',
                                left: `${sliderValue * 0.94 + 3}%`,
                                transform: 'translate(-50%, -50%)',
                                zIndex: 1,
                                pointerEvents: 'none',
                                boxShadow: '0 1px 3px rgba(0,0,0,0.5)',
                            }} />
                        </div>

                        <div style={{
                            fontSize: '16px',
                            color: 'white',
                            // fontWeight: 'bold',
                            minWidth: '45px',
                            textAlign: 'right',
                            marginTop: '2px',
                            transform: 'translateY(-10%)',
                            marginLeft: '10px',

                        }}>
                            {sliderValue}%
                        </div>
                    </div>

                </div>


                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0px 25px',
                    boxSizing: 'border-box',
                    marginTop: '15px',
                }}>
                    <CreateVideoButton
                        setReloadBundles={setReloadBundles}
                        image={bundleData.images[5]}
                        onCreateVideo={onCreateVideo}
                        isCreatingPSLVideo={isCreatingPSLVideo}
                        setIsCreatingPSLVideo={setIsCreatingPSLVideo}
                    />
                </div>
            </div>

            {/* <div style={{
                flex: '1 0 0%',
                backgroundColor: 'rgba(0,0,0,0.5)',
                borderRadius: '20px',
                padding: '10px 20px',
                boxSizing: 'border-box',
                fontSize: '16px',
                textAlign: 'start',
                overflowY: 'none',
                color: 'rgb(190,190,190)',
            }}>
                {descriptionText.split('\n\n').map((paragraph, index) => (
                    <div key={index}>
                        {paragraph.split('\n').map((text, i) => (
                            <div key={i}>{text}</div>
                        ))}
                        {index < descriptionText.split('\n\n').length - 1 && <br />}
                    </div>
                ))}
            </div> */}
        </div>
    )
}

export default BundleMorphT;