import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser, updateUser } from '../features/auth/authSlice';
import { getCurrentUser } from '../services/api';
import WebappContainer from "../components/common/WebappContainer";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import LooxerButton from "../components/common/Button";
import LoadingThingie from "../components/common/LoadingThingie";


const BuySection = ({
    handlePayment
}) => {
    return (

        <div style={{
            width: '100%',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'stretch',
            zIndex: 2,
            gap: '10px',

            padding: '15px',
            boxSizing: 'border-box',

            textAlign: 'center',
        }}>
            <div style={{
                color: 'rgb(180,180,180)',
                fontSize: '12px',
            }}>
                The image processing costs us money, so we have to charge a small fee to keep the lights on. Hope you understand 🤝
            </div>

            <LooxerButton
                active={true}
                text="Start exploring your potential"
                onClick={handlePayment}
            />
            <div style={{
                // backgroundColor: 'red',
                color: 'white',
                fontSize: '12px',
                marginBottom: '20px',

                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '7px',

                wordWrap: 'break-word',
            }}>
                One time payment of 4.99$
                <div style={{
                    backgroundColor: 'white',

                    width: '3px',
                    height: '3px',

                    borderRadius: '100%'
                }} />
                10 credits
                <div style={{
                    backgroundColor: 'white',

                    width: '3px',
                    height: '3px',

                    borderRadius: '100%'
                }} />
                <div>Enough for</div>
                <div>5 morphs/2 videos/5 analysis</div>
                <div style={{
                    backgroundColor: 'white',

                    width: '3px',
                    height: '3px',

                    borderRadius: '100%'
                }} />
                Unlocks all locked content
            </div>
        </div>
    )
}

const BoughtSection = () => {
    const navigate = useNavigate();

    return (
        <div style={{
            // backgroundColor: 'red',
            width: '100%',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            zIndex: 2,

            padding: '15px',
            boxSizing: 'border-box',

            textAlign: 'center',
        }}>

            <div style={{
                color: 'white',
                fontSize: '20px',
                fontWeight: '600',

                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '7px'
            }}>
                You've got 10 credits!✔️
            </div>

            <div style={{
                color: 'rgb(180,180,180)',
                fontSize: '14px',
                marginBottom: '20px',
            }}>
                Thank you for your purchase
            </div>

            <LooxerButton
                style={{
                    alignSelf: 'center',
                    // width: '40%',
                }}
                active={true}
                text="Close"
                onClick={() => {
                    navigate('/');
                }}
            />
        </div>
    )
}

const OneTimePaymentScreen = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isBought, setIsBought] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);
    const creditsRef = useRef(user.credits);

    useEffect(() => {
        setIsBought(false);
        // handlePayment(); // TODO
    }, []);

    useEffect(() => {
        creditsRef.current = user.credits;
    }, [user.credits]);

    const handlePayment = async () => {
        setIsLoading(true);
        window.open('https://looxer.gumroad.com/l/svsvt', '_blank'); // TODO

        const initialCredits = creditsRef.current;
        const pollInterval = setInterval(() => {
            getCurrentUser().then(response => {
                if (response.success) {
                    console.log('New credits:', response.user.credits);
                    dispatch(updateUser(response.user));
                    if (response.user.credits > initialCredits) {
                        console.log('Got added credits:', response.user.credits);
                        clearInterval(pollInterval);
                        setIsLoading(false);
                        setIsBought(true);
                    }
                }
            });
        }, 1000);
    };

    return (
        <WebappContainer style={{
            alignItems: 'center',
        }}>
            <div style={{
                background: `
linear-gradient(to bottom, 
rgb(0, 0, 0, 0) 0%,
rgb(0, 0, 0, 0) 5%,
rgb(0, 0, 0, 1) 50%,
rgb(0, 0, 0, 1) 100%
          )`,
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                zIndex: 0,
            }}>
            </div>

            <div style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
            }}>
                <IoClose color="rgb(150,150,150)" size="26px" onClick={() => navigate('/')} />
            </div>

            <video autoPlay
                loop
                muted
                playsInline

                style={{
                    width: '100px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    zIndex: 1,
                }}
            >
                <source src="/images/alpha_logo.webm" />
            </video>

            <div style={{
                // backgroundColor: 'red',
                flex: '1 0 0%',

                zIndex: 2,

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',

                position: 'relative',
            }}>
                <div style={{
                    color: 'white',
                    fontSize: '30px',
                    fontWeight: '600',
                    fontStyle: 'italic',
                    zIndex: 2,
                }}>
                    SUPERCHARGE
                </div>
                <div>
                    See your full potential.
                </div>

                <div style={{
                    // backgroundColor:'red',

                    flex: '1 0 0%',
                    width: '100%',

                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',

                    zIndex: 2,
                }}>
                    <div style={{
                        width: '80%',
                        aspectRatio: '1/1',
                        alignSelf: 'center',
                        marginTop: '5px',
                        marginBottom: '20px',
                        outline: '3px white solid',
                        borderRadius: '10px',

                        overflow: 'hidden',
                        position: 'relative',

                    }}>
                        <video autoPlay
                            loop
                            muted
                            playsInline
                            alt="demo_vid2"
                            style={{

                                width: '100%',

                                // boxShadow: 'rgba(205, 31, 167, 0.56) 0px 22px 70px 4px',

                                // height: '100%',
                                // transform:'translateY(5px)',
                            }}
                        >
                            <source src="/images/1228.mp4" />
                        </video>

                        <div style={{
                            backgroundColor: 'white',

                            position: 'absolute',
                            bottom: '0',
                            right: '0',

                            width: '40%',
                            height: '17%',

                            borderTopLeftRadius: '10px',

                            color: 'black',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '18px',

                            padding: '5px',
                            boxSizing: 'border-box',

                            textAlign: 'center',

                        }}>
                            Created with Looxer
                        </div>
                    </div>
                </div>

                {
                    isBought ? (
                        <BoughtSection />
                    ) : (
                        <BuySection
                            handlePayment={handlePayment}
                        />
                    )
                }

                {
                    isLoading && (
                        <div style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            top: '0',
                            left: '0',

                            backgroundColor: 'rgba(0,0,0,0.5)',
                            zIndex: 2,

                            backdropFilter: 'blur(10px)',
                            WebkitBackdropFilter: 'blur(10px)',

                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',

                        }}>
                            <LoadingThingie style={{
                                marginBottom: '10px',
                            }} />

                            <div>
                                Waiting for credits to update..
                            </div>

                            <div style={{
                                maxWidth: '80%',
                                textAlign: 'center',

                                fontSize: '12px',
                                color: 'rgb(180,180,180)',
                            }}>
                                Closing or refreshing this page will not interrupt the payment process.
                            </div>
                        </div>
                    )
                }
            </div>
        </WebappContainer>
    )
}

export default OneTimePaymentScreen;
