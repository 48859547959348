import React, { useState, useRef } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { IoMdDownload } from 'react-icons/io';
import { useSwipeable } from 'react-swipeable';
import { useNavigate } from 'react-router-dom';
import { formatDateString } from '../../utils/UtilFuncs';
import LooxerButton from '../common/Button';
// Note: In a real implementation, you would need to install html2canvas via npm:
// npm install --save html2canvas
// This is just for demonstration purposes
const html2canvas = window.html2canvas || (() => Promise.resolve(null));

// Mock data to simulate what we might receive from the backend
const mockAnalysisDetails = {
    canthalTilt: {
        leftEyeAngle: 5.2,
        rightEyeAngle: 4.8,
        averageAngle: 5.0,
        symmetry: 92,
        classification: 'Hunter',
        score: 85,
        imageUrl: 'https://picsum.photos/200/300?random=1',
        // description: 'Canthal tilt refers to the angle of your eyes. A positive tilt (upward angle) is associated with more attractive facial features. Your eyes have a positive canthal tilt, which is considered aesthetically balanced.',
        recommendation: 'Your eye tilt is within the ideal range. No specific improvements needed.'
    },
    fwhr: {
        facialWidth: 142,
        facialHeight: 190,
        ratio: 0.747,
        comparedToAverage: '+5%',
        score: 78,
        imageUrl: 'https://picsum.photos/200/300?random=2',
        // description: 'Facial Width-to-Height Ratio (FWHR) is the width of your face divided by the height. A higher ratio is associated with perceived dominance and masculinity.',
        recommendation: 'Your FWHR is slightly above average, contributing to a strong facial appearance. Maintaining low body fat will enhance this feature.'
    },
    philtumChinRatio: {
        philtumLength: 15,
        chinLength: 45,
        ratio: 0.33,
        comparedToAverage: '-2%',
        score: 92,
        imageUrl: 'https://picsum.photos/200/300?random=3',
        // description: 'The philtrum-to-chin ratio measures the proportion between your philtrum (area between nose and upper lip) and chin. Ideal ratios contribute to facial harmony.',
        recommendation: 'Your ratio is near ideal. Maintaining good posture can help preserve this balance.'
    },
    lipsNoseRatio: {
        lipLength: 52,
        noseLength: 48,
        ratio: 1.08,
        comparedToAverage: '+3%',
        score: 88,
        imageUrl: 'https://picsum.photos/200/300?random=4',
        // description: 'This ratio compares the width of your lips to the width of your nose. A balanced ratio contributes to overall facial harmony.',
        recommendation: 'Your lips and nose are well-proportioned. No specific improvements needed.'
    },
    facialFat: {
        percentage: 12,
        score: 90,
        imageUrl: 'https://picsum.photos/200/300?random=5',
        // description: 'Facial fat percentage affects definition and structure visibility. Lower percentages (without being too low) generally enhance facial aesthetics.',
        recommendation: 'Your facial fat percentage is in a good range. Maintain your current diet and exercise routine.'
    }
};

// Download helper function
const downloadSlideAsImage = (slideRef, fileName) => {
    if (!slideRef.current || !window.html2canvas) {
        alert('Download functionality requires html2canvas library. In a real implementation, this would work.');
        return;
    }

    // Capture the entire slide content using html2canvas
    html2canvas(slideRef.current, {
        scrollY: -window.scrollY,
        scrollX: -window.scrollX,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
        useCORS: true,
        allowTaint: true,
        onclone: (document) => {
            // Fix any styling issues in the cloned document if needed
            const slideClone = document.querySelector('[data-slide]');
            if (slideClone) {
                slideClone.style.width = 'fit-content';
                slideClone.style.height = 'fit-content';
                slideClone.style.overflow = 'visible';
            }
        }
    }).then(canvas => {
        // Convert canvas to data URL
        const dataUrl = canvas.toDataURL('image/png');

        // Create a download link
        const link = document.createElement('a');
        link.download = fileName;
        link.href = dataUrl;
        link.click();
    }).catch(error => {
        console.error('Error generating image:', error);
        alert('There was an error generating the image.');
    });
};

// Download button component
const DownloadButton = ({ onClick }) => (
    <div
        style={{
            position: 'absolute',
            top: '15px',
            left: '0px',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            zIndex: 10,
        }}
        onClick={onClick}
    >
        <IoMdDownload style={{ color: 'rgb(200,200,200)', fontSize: '22px' }} />
    </div>
);

// Component for rendering metric circles in overview
const MetricCircle = ({ name, score, size = 80 }) => {
    // Define color based on score
    const getColor = (score) => {
        if (score >= 90) return '#4CAF50'; // Green
        if (score >= 70) return '#8BC34A'; // Light Green
        if (score >= 50) return '#FFC107'; // Amber
        if (score >= 30) return '#FF9800'; // Orange
        return '#F44336'; // Red
    };

    const color = getColor(score);
    const circumference = 2 * Math.PI * 40; // Circle circumference (2πr)
    const dashOffset = circumference - (score / 100) * circumference;

    return (
        <div style={{
            // backgroundColor: 'red',
            width: `${size}px`,
            // backgroundColor: 'red',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

            margin: '0 2px',

            padding: '10px',
            boxSizing: 'border-box'
        }}>
            <svg
                style={{
                    width: '100%',
                    aspectRatio: '1/1',
                }}
                viewBox="0 0 100 100"
            >
                {/* Background circle */}
                <circle
                    cx="50"
                    cy="50"
                    r="43"
                    fill="transparent"
                    stroke="rgba(255,255,255,0.2)"
                    strokeWidth="12"
                />
                {/* Progress circle */}
                <circle
                    cx="50"
                    cy="50"
                    r="43"
                    fill="transparent"
                    stroke={color}
                    strokeWidth="12"
                    strokeDasharray={circumference}
                    strokeDashoffset={dashOffset}
                    strokeLinecap="round"
                    transform="rotate(-90 50 50)"
                />
                {/* Score text */}
                <text
                    x="50"
                    y="55"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fill="white"
                    fontSize="25"
                    fontWeight="bold"
                >
                    {score}
                </text>
            </svg>
            <div style={{
                marginTop: '10px',
                fontSize: `${size * 0.1}px`,
                textAlign: 'center',
                color: 'white',
                fontWeight: '600',
                maxWidth: `${size * 1}px`,
                wordWrap: 'break-word',
                backgroundColor: 'rgba(40, 40, 40, 0.8)',
                padding: '4px 8px',
                borderRadius: '6px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
                backdropFilter: 'blur(2px)',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                textTransform: 'uppercase',
                letterSpacing: '0.5px',
            }}>
                {name}
            </div>
        </div>
    );
};

// Overview slide component
const OverviewSlide = ({ analysisData, analysisDetails }) => {
    const slideRef = useRef(null);
    const totalScore = Math.round(
        (analysisDetails.canthalTilt.score +
            analysisDetails.fwhr.score +
            analysisDetails.philtumChinRatio.score +
            analysisDetails.lipsNoseRatio.score +
            analysisDetails.facialFat.score) / 5
    );

    return (
        <div
            ref={slideRef}
            data-slide="overview"
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '10px',
                paddingTop: '15px',
                boxSizing: 'border-box',
                height: '100%',
                overflowY: 'auto',
                position: 'relative',
            }}
        >
            <DownloadButton
                onClick={(e) => {
                    e.stopPropagation(); // Prevent slide change when clicking download
                    downloadSlideAsImage(slideRef, 'facial-analysis-overview.png');
                }}
            />

            <div style={{
                fontSize: '22px',
                marginBottom: '10px',
            }}>
                Facial Analysis Overview
            </div>

            {/* Image and Overall Score Row */}
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '90%',
                maxWidth: '500px',
                gap: '15px',
            }}>
                {/* Main Image */}
                <div style={{
                    width: '55%',
                    aspectRatio: '1/1',
                    borderRadius: '20px',
                    overflow: 'hidden',
                    position: 'relative',
                    boxShadow: 'rgb(0, 0, 0) 0px 20px 30px -10px',
                }}>
                    <img
                        src={analysisData.images[0].url}
                        alt="analysis"
                        style={{
                            position: 'absolute',
                            width: '100%',
                            borderRadius: '10px',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%) scale(1.05)',
                        }}
                    />
                </div>

                {/* Overall Score */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '40%',
                }}>
                    <MetricCircle name="Overall Score" score={totalScore} size={120} />
                </div>
            </div>

            {/* Metrics Row */}
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
                width: '100%',
                maxWidth: '400px',
                marginTop: '10px',
                gap: '15px 0',
            }}>
                <MetricCircle name="Canthal Tilt" score={analysisDetails.canthalTilt.score} />
                <MetricCircle name="Face Ratio" score={analysisDetails.fwhr.score} />
                <MetricCircle name="Philtrum-Chin" score={analysisDetails.philtumChinRatio.score} />
                <MetricCircle name="Lips-Nose" score={analysisDetails.lipsNoseRatio.score} />
                {/* <MetricCircle name="Facial Fat [%]" score={analysisDetails.facialFat.score} /> */}
            </div>

            <div style={{
                fontSize: '14px',
                color: 'rgba(255,255,255,0.7)',
                textAlign: 'center',
                margin: '20px 15px 0',
            }}>
                Swipe left to view detailed metrics for each feature
            </div>
        </div>
    );
};

// Detail slide for each metric
const MetricDetailSlide = ({ title, data, showBack, hasBoughtBefore }) => {
    const [fullImage, setFullImage] = useState(false);
    const slideRef = useRef(null);
    const navigate = useNavigate();
    return (
        <div
            ref={slideRef}
            data-slide={title.toLowerCase().replace(/\s+/g, '-')}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                height: '100%',
                overflowY: 'auto',
                padding: '15px',
                position: 'relative',
            }}
        >

            {
                !hasBoughtBefore && (
                    <div style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.5)',

                        position: 'absolute',
                        top: '50%',
                        left: '50%',

                        transform: 'translate(-50%, -50%)',
                        zIndex: 100,
                    }}>

                        <div style={{
                            width: '100%',
                            height: '100%',

                            position: 'absolute',
                            top: '50%',
                            left: '50%',

                            transform: 'translate(-50%, -50%)',

                            //blur
                            backdropFilter: 'blur(16px)',
                            WebkitBackdropFilter: 'blur(16px)',

                        }} />

                        <LooxerButton style={{
                            position: 'absolute',
                            width: '60%',
                            top: '50%',
                            left: '50%',

                            transform: 'translate(-50%, -50%)',
                        }}
                            onClick={() => {
                                navigate('/more-credits');
                            }}
                            text="See detailed analysis"
                            active={true}
                        />
                    </div>
                )
            }

            <DownloadButton
                onClick={(e) => {
                    e.stopPropagation(); // Prevent slide change when clicking download
                    downloadSlideAsImage(slideRef, `facial-analysis-${title.toLowerCase().replace(/\s+/g, '-')}.png`);
                }}
            />

            <div style={{
                fontSize: '22px',
                textAlign: 'center',
                marginBottom: '20px',
            }}>
                {title}
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginBottom: '20px',
                flexWrap: 'wrap',
            }}>

                {/* Image */}
                <div style={{
                    width: fullImage ? '100%' : '45%',
                    aspectRatio: '1/1',
                    borderRadius: '15px',
                    overflow: 'hidden',
                    position: 'relative',
                    boxShadow: 'rgb(0, 0, 0) 0px 10px 20px -10px',
                    transition: 'all 0.3s ease-out',
                }}
                    onClick={() => setFullImage(!fullImage)}
                >
                    <img
                        src={data.imageUrl}
                        alt={title}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                </div>

                {/* Score Circle */}
                <div style={{ flex: '0 0 auto' }}>
                    <MetricCircle name="Score" score={data.score} size={120} />
                </div>

            </div>

            <div style={{
                flex: '1 0 0%',
                backgroundColor: 'rgba(0,0,0,0.5)',
                borderRadius: '20px',
                padding: '15px',
                boxSizing: 'border-box',
                fontSize: '16px',
                color: 'rgb(190,190,190)',
            }}>
                <div style={{
                    marginBottom: '15px',
                    fontSize: '15px',
                }}>
                    {data.description}
                </div>

                {/* Metrics Details */}
                <div style={{
                    marginBottom: '20px',
                }}>
                    {title === 'Canthal Tilt' && (
                        <>
                            <MetricRow name="Left Eye Angle" value={`${data.leftEyeAngle}°`} />
                            <MetricRow name="Right Eye Angle" value={`${data.rightEyeAngle}°`} />
                            <MetricRow name="Average Angle" value={`${data.averageAngle}°`} />
                            <MetricRow name="Symmetry" value={`${data.symmetry}%`} />
                            <MetricRow name="Classification" value={data.classification} />
                        </>
                    )}

                    {title === 'Facial Width-Height Ratio' && (
                        <>
                            <MetricRow name="Facial Width" value={`${data.facialWidth}mm`} />
                            <MetricRow name="Facial Height" value={`${data.facialHeight}mm`} />
                            <MetricRow name="Ratio" value={data.ratio.toFixed(3)} />
                            <MetricRow name="Compared to Average" value={data.comparedToAverage} />
                        </>
                    )}

                    {title === 'Philtrum-to-Chin Ratio' && (
                        <>
                            <MetricRow name="Philtrum Length" value={`${data.philtumLength}mm`} />
                            <MetricRow name="Chin Length" value={`${data.chinLength}mm`} />
                            <MetricRow name="Ratio" value={data.ratio.toFixed(2)} />
                            <MetricRow name="Compared to Average" value={data.comparedToAverage} />
                        </>
                    )}

                    {title === 'Lips-to-Nose Ratio' && (
                        <>
                            <MetricRow name="Lip Length" value={`${data.lipLength}mm`} />
                            <MetricRow name="Nose Length" value={`${data.noseLength}mm`} />
                            <MetricRow name="Ratio" value={data.ratio.toFixed(2)} />
                            <MetricRow name="Compared to Average" value={data.comparedToAverage} />
                        </>
                    )}

                    {title === 'Facial Fat Percentage' && (
                        <>
                            <MetricRow name="Estimated Percentage" value={`${data.percentage}%`} />
                        </>
                    )}
                </div>

                <div style={{
                    marginTop: '15px',
                    fontSize: '15px',
                    fontStyle: 'italic',
                    color: 'rgb(150,150,150)',
                }}>
                    <strong>Note:</strong> {data.recommendation}
                </div>
            </div>

            <div style={{
                fontSize: '14px',
                color: 'rgba(255,255,255,0.7)',
                textAlign: 'center',
                margin: '20px 0 10px',
            }}>
                {showBack ? 'Swipe right to return to overview' : 'Swipe left for next metric'}
            </div>
        </div>
    );
};

// Helper component for metric rows
const MetricRow = ({ name, value }) => (
    <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '8px',
        fontSize: '14px',
    }}>
        <span>{name}:</span>
        <span style={{ fontWeight: 'bold' }}>{value}</span>
    </div>
);

// Main component for facial analysis
const FacialAnalysisDetails = ({ analysisData, dateString, hasBoughtBefore }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    // Use mock data as fallback if there are no analysis details
    const analysisDetails = analysisData.analysis_details || mockAnalysisDetails;

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if (currentSlide < 4) {
                setCurrentSlide(currentSlide + 1);
            }
        },
        onSwipedRight: () => {
            if (currentSlide > 0) {
                setCurrentSlide(currentSlide - 1);
            }
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    // Create array of slide components
    const slides = [
        <OverviewSlide
            key="overview"
            analysisData={analysisData}
            analysisDetails={analysisDetails}
        />,
        <MetricDetailSlide
            key="canthal"
            title="Canthal Tilt"
            data={analysisDetails.canthalTilt}
            showBack={false}
            hasBoughtBefore={hasBoughtBefore}
        />,
        <MetricDetailSlide
            key="fwhr"
            title="Facial Width-Height Ratio"
            data={analysisDetails.fwhr}
            showBack={false}
            hasBoughtBefore={hasBoughtBefore}
        />,
        <MetricDetailSlide
            key="philtum"
            title="Philtrum-to-Chin Ratio"
            data={analysisDetails.philtumChinRatio}
            showBack={false}
            hasBoughtBefore={hasBoughtBefore}
        />,
        <MetricDetailSlide
            key="lips"
            title="Lips-to-Nose Ratio"
            data={analysisDetails.lipsNoseRatio}
            showBack={false}
            hasBoughtBefore={hasBoughtBefore}
        />,
        // <MetricDetailSlide
        //     key="fat"
        //     title="Facial Fat Percentage"
        //     data={analysisDetails.facialFat}
        //     showBack={true}
        //     hasBoughtBefore={hasBoughtBefore}
        // />
    ];

    return (
        <div
            {...handlers}
            style={{
                minHeight: 1,
                flex: '1 0 0%',
                zIndex: 1,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                padding: '20px 0',
                boxSizing: 'border-box',
                overflow: 'hidden',
            }}
        >
            {/* <div style={{
                fontSize: '25px',
                fontWeight: 'bold',
                textAlign: 'center',
                marginBottom: '10px',
            }}>
                Analysis: {dateString}
            </div> */}

            {/* Slide indicator dots */}
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '10px',
            }}>
                {slides.map((_, index) => (
                    <div
                        key={index}
                        style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            backgroundColor: index === currentSlide ? 'white' : 'rgba(255,255,255,0.3)',
                            margin: '0 5px',
                            transition: 'background-color 0.3s',
                        }}
                        onClick={() => setCurrentSlide(index)}
                    />
                ))}
            </div>

            {/* Slides container */}
            <div style={{
                position: 'relative',
                flex: '1 0 0%',
                overflow: 'hidden',
            }}>
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: `${(index - currentSlide) * 100}%`,
                            width: '100%',
                            height: '100%',
                            transition: 'left 0.3s ease-out',
                            padding: '0 15px',
                            boxSizing: 'border-box',
                        }}
                    >
                        {slide}
                    </div>
                ))}
            </div>
        </div>
    );
};

const AnalysisResult = ({
    analysisData,
    onClose,
    hasBoughtBefore
}) => {
    // Format the date string from the created_at timestamp in analysisData
    const dateString = formatDateString(analysisData.created_at);

    return (
        <div style={{
            width: '100%',
            height: '100%',
            zIndex: 1,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'stretch',
            minHeight: 1,
            boxSizing: 'border-box',
            overflowY: 'scroll',
        }}>
            <IoCloseSharp style={{
                position: 'absolute',
                left: '10px',
                top: '10px',
                fontSize: '30px',
                cursor: 'pointer',
                zIndex: 2,
            }}
                onClick={onClose}
            />

            <FacialAnalysisDetails
                analysisData={analysisData}
                dateString={dateString}
                hasBoughtBefore={hasBoughtBefore}
            />
        </div>
    );
};

export default AnalysisResult; 