
export function formatDateString(dateString) {

    const now = new Date();
    const inputDate = new Date(dateString);

    // Calculate the time difference in milliseconds
    const timeDifference = now - inputDate;

    // Helper to format ordinal suffix for the day
    const getOrdinalSuffix = (day) => {
        if (day > 3 && day < 21) return 'th'; // Special case for teens
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    // Case 1: "Just now" (within 60 seconds)
    if (timeDifference < 60 * 5000) {
        return "Just now";
    }


    // Case 2: "X hours ago" (within 24 hours)
    const minutesDifference = Math.floor(timeDifference / (60 * 1000));
    if (minutesDifference < 60) {
        return `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
    }


    // Case 2: "X hours ago" (within 24 hours)
    const hoursDifference = Math.floor(timeDifference / (60 * 60 * 1000));
    if (hoursDifference < 24) {
        return `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
    }

    // Format month and day
    const options = { month: 'long', day: 'numeric' };
    const formattedDate = inputDate.toLocaleDateString('en-US', options);

    // Case 3: "December 10th" (within the same year)
    if (inputDate.getFullYear() === now.getFullYear()) {
        const day = inputDate.getDate();
        const ordinalSuffix = getOrdinalSuffix(day);
        return `${formattedDate}${ordinalSuffix}`;
    }

    // Case 4: "January 13th, 2022" (different year)
    const fullDateOptions = { month: 'short', day: 'numeric', year: 'numeric' };
    return inputDate.toLocaleDateString('en-US', fullDateOptions);
}

export function getSecondsSince(dateString) {
    // Parse the input date string into a Date object
    const inputDate = new Date(dateString);

    // Get the current time as a Date object
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const differenceInMs = currentDate - inputDate;

    // Convert the difference to seconds
    const differenceInSeconds = Math.floor(differenceInMs / 1000);

    return differenceInSeconds;
}
