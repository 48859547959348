import { useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
const GuideCard = ({ guide, onClick }) => {
    return (
        <div style={{
            backgroundColor: 'rgb(80,80,80, 0.6)',

            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',

            padding: '17px',
            boxSizing: 'border-box',

            borderRadius: '15px',
            fontSize: '17px',

            cursor: 'pointer',

        }} onClick={onClick}>

            <div style={{
                fontSize: '17px',
                fontWeight: 'bold',

                marginBottom: '5px',
            }}>
                {guide.title}
            </div>

            <div style={{
                fontSize: '13px',
                color: 'rgb(190,190,190)',
            }}>
                {guide.description}
            </div>

        </div>
    )
}

const GuideComponent = ({ title, guideUniqueName, onClose }) => {
    return (
        <div style={{

            width: '100%',
            height: '100%',

            zIndex: 1,

            position: 'relative',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',

            minHeight: 1,

            padding: '25px 20px',
            boxSizing: 'border-box',

            overflowY: 'scroll',
        }}>

            <IoCloseSharp style={{
                position: 'absolute',
                left: '10px',
                top: '10px',
                fontSize: '30px',
                cursor: 'pointer',
                zIndex: 2,
            }}
                onClick={onClose}
            />

            <div style={{
                fontSize: '20px',
                fontWeight: 'bold',

                textAlign: 'center',
                marginTop: '20px',
            }}>
                {title}
            </div>

        </div>
    )
}

const GuidesScreen = () => {
    const [showingGuide, setShowingGuide] = useState(null);
    const [guideUniqueName, setGuideUniqueName] = useState(null);

    const guides = [
        {
            "title": "Intro to Looxer's Library",
            "description": "Learn how to use Looxer's Library to get the most out of your Looxer experience.",
            "uniqueName": "intro-to-looxer-library",
        },
        {
            "title": "Getting Leaner",
            "description": "The secret sauce for burning fat with minimal muscle loss.",
            "uniqueName": "getting-leaner",
        },
        {
            "title": "Facial Hair Maintenance",
            "description": "Using Minoxidil and more to transform your facial features.",
            "uniqueName": "facial-hair",
        },
        {
            "title": "The Truth about Mewing & Posture",
            "description": "The importance of posture and mewing, and some hard truths about these topics.",
            "uniqueName": "mewing-posture",
        },
        {
            "title": "Supplements: What really moves the needle",
            "description": "A guide to the supplements that actually work, and the ones that don't.",
            "uniqueName": "supplements",
        }
    ]


    return (
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            zIndex: 1,

            overflowY: 'hidden',
        }}>
            {
                !showingGuide ? (
                    <>
                        <h2>Guides</h2>
                        <div style={{
                            width: '100%',
                            flex: '1 0 0%',

                            padding: '15px 20px',
                            boxSizing: 'border-box',

                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'stretch',
                            justifyContent: 'flex-start',

                            gap: '15px',

                            overflowY: 'scroll',
                        }}>
                            {guides.map((guide) => (
                                <GuideCard
                                    key={guide.title}
                                    guide={guide}
                                    onClick={() => {
                                        setShowingGuide(guide);
                                        setGuideUniqueName(guide.uniqueName);
                                    }} />
                            ))}
                        </div></>
                )
                    :
                    (
                        <GuideComponent
                            key={guideUniqueName}
                            title={showingGuide.title}
                            guideUniqueName={guideUniqueName}
                            onClose={() => {
                                setShowingGuide(null);
                                setGuideUniqueName(null);
                            }}
                        />
                    )

            }

        </div>
    )
}

export default GuidesScreen;
